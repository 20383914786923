import React, { Component } from "react";
import './Greeting.css';

export default class Greeting extends Component {
  render() {
    return (
      <div className="greeting-wrapper">
        <h1>Hi, I am Jonathan Lane</h1>
      </div>
    );
  }
}
